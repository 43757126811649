import antdEnUS from "antd/es/locale/en_US";
import uzMsg from "../locales/uz_UZ.json";

const UzLang = {
  antd: antdEnUS,
  locale: "uz",
  messages: {
    ...uzMsg,
  },
};
export default UzLang;
