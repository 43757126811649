import enLang from "./entries/en_US";
import ruLang from "./entries/ru_RU";
import UzLang from "./entries/uz_UZ";

const AppLocale = {
  en: enLang,
  ru: ruLang,
  uz: UzLang,
};

export default AppLocale;
