import {
  DashboardOutlined,
  AppstoreOutlined,
  FileTextOutlined,
  FundOutlined,
  ShoppingCartOutlined,
  BookOutlined,
  SnippetsOutlined,
  UnorderedListOutlined,
  PartitionOutlined,
  PicCenterOutlined,
  UserOutlined,
  PercentageOutlined,
  AppstoreAddOutlined,
  NodeIndexOutlined,
  QuestionCircleOutlined,
  LineChartOutlined,
  DollarOutlined,
  TransactionOutlined,
  ProjectOutlined,
  VerticalAlignBottomOutlined,
  OneToOneOutlined,
  ContainerOutlined,
  MoneyCollectOutlined,
  RiseOutlined,
  DeliveredProcedureOutlined,
  CommentOutlined,
  LoginOutlined,
  HistoryOutlined,
  WarningOutlined,
  FormOutlined,
  ClusterOutlined,
  BorderlessTableOutlined,
  RollbackOutlined,
  SwapOutlined,
  BorderOuterOutlined,
} from "@ant-design/icons";
import { APP_PREFIX_PATH } from "configs/AppConfig";

const dashBoardNavTree = [
  {
    key: "dashboards",
    path: `${APP_PREFIX_PATH}/dashboards`,
    title: "sidenav.dashboard",
    icon: DashboardOutlined,
    breadcrumb: false,
    submenu: [
      // {
      //   key: "dashboards-default",
      //   path: `${APP_PREFIX_PATH}/dashboards/default`,
      //   title: "sidenav.dashboard.default",
      //   icon: DashboardOutlined,
      //   breadcrumb: false,
      //   submenu: [],
      // },
      // {
      //   key: "dashboards-analytic",
      //   path: `${APP_PREFIX_PATH}/dashboards/analytic`,
      //   title: "sidenav.dashboard.analytic",
      //   icon: DotChartOutlined,
      //   breadcrumb: false,
      //   submenu: [],
      // },
      {
        key: "dashboards-sales",
        path: `${APP_PREFIX_PATH}/dashboards/sales`,
        title: "sidenav.dashboard.sales",
        icon: FundOutlined,
        breadcrumb: false,
        submenu: [],
      },
    ],
  },
];

const appsNavTree = [
  {
    key: "apps",
    path: `${APP_PREFIX_PATH}/apps`,
    title: "sidenav.apps",
    icon: AppstoreOutlined,
    breadcrumb: false,
    submenu: [
      {
        key: "products-actions",
        path: `${APP_PREFIX_PATH}/apps/products-actions`,
        title: "sidenav.apps.products-actions",
        icon: FormOutlined,
        breadcrumb: true,
        submenu: [
          {
            key: "income",
            path: `${APP_PREFIX_PATH}/apps/products-actions/product-income`,
            title: "sidenav.apps.products-actions.product-income",
            icon: VerticalAlignBottomOutlined,
            breadcrumb: true,
            submenu: [],
          },
          {
            key: "install-price",
            path: `${APP_PREFIX_PATH}/apps/products-actions/install-prices`,
            title: "sidenav.apps.products-actions.install-prices",
            icon: WarningOutlined,
            breadcrumb: true,
            submenu: [],
          },
          {
            key: "inventory",
            path: `${APP_PREFIX_PATH}/apps/products-actions/inventory`,
            title: "sidenav.apps.products-actions.inventory",
            icon: BorderOuterOutlined,
            breadcrumb: true,
            submenu: [],
          },
          {
            key: "product-transfer",
            path: `${APP_PREFIX_PATH}/apps/products-actions/product-transfer`,
            title: "sidenav.apps.products-actions.product-transfer",
            icon: SwapOutlined,
            breadcrumb: true,
            submenu: [],
          },
        ],
      },
      {
        key: "spravochnik",
        path: `${APP_PREFIX_PATH}/apps/spravochnik`,
        title: "sidenav.apps.spravochnik",
        icon: ClusterOutlined,
        breadcrumb: true,
        submenu: [
          {
            key: "category",
            path: `${APP_PREFIX_PATH}/apps/spravochnik/category`,
            title: "sidenav.apps.spravochnik.category",
            icon: BorderlessTableOutlined,
            breadcrumb: true,
            submenu: [],
          },
          {
            key: "brands",
            path: `${APP_PREFIX_PATH}/apps/spravochnik/brands`,
            title: "sidenav.apps.spravochnik.brands",
            icon: BorderlessTableOutlined,
            breadcrumb: true,
            submenu: [],
          },
          {
            key: "manufacturers",
            path: `${APP_PREFIX_PATH}/apps/spravochnik/manufacturers`,
            title: "sidenav.apps.spravochnik.manufacturers",
            icon: BorderlessTableOutlined,
            breadcrumb: true,
            submenu: [],
          },
          {
            key: "attributes",
            path: `${APP_PREFIX_PATH}/apps/spravochnik/attributes`,
            title: "sidenav.apps.spravochnik.attributes",
            icon: BorderlessTableOutlined,
            breadcrumb: true,
            submenu: [],
          },
          {
            key: "providers",
            path: `${APP_PREFIX_PATH}/apps/spravochnik/providers`,
            title: "sidenav.apps.spravochnik.providers",
            icon: BorderlessTableOutlined,
            breadcrumb: true,
            submenu: [],
          },
        ],
      },
      {
        key: "warehouses",
        path: `${APP_PREFIX_PATH}/apps/warehouses`,
        title: "sidenav.apps.warehouses",
        icon: UnorderedListOutlined,
        breadcrumb: true,
        submenu: [],
      },
      {
        key: "banners",
        path: `${APP_PREFIX_PATH}/apps/banners`,
        title: "sidenav.apps.banner",
        icon: PicCenterOutlined,
        breadcrumb: true,
        submenu: [],
      },
      {
        key: "requests",
        path: `${APP_PREFIX_PATH}/apps/requests`,
        title: "sidenav.apps.requests",
        icon: DeliveredProcedureOutlined,
        breadcrumb: true,
        submenu: [
          {
            key: "discounts",
            path: `${APP_PREFIX_PATH}/apps/requests/complains-and-suggestions`,
            title: "sidenav.apps.requests.complain_and_suggestion",
            icon: CommentOutlined,
            breadcrumb: true,
            submenu: [],
          },
          {
            key: "cashback",
            path: `${APP_PREFIX_PATH}/apps/requests/find-cheaper-one`,
            title: "sidenav.apps.requests.find_cheaper_one",
            icon: QuestionCircleOutlined,
            breadcrumb: true,
            submenu: [],
          },
        ],
      },
      {
        key: "marketing",
        path: `${APP_PREFIX_PATH}/apps/marketing`,
        title: "sidenav.apps.marketing",
        icon: LineChartOutlined,
        breadcrumb: true,
        submenu: [
          {
            key: "discounts",
            path: `${APP_PREFIX_PATH}/apps/marketing/discount`,
            title: "sidenav.apps.discount",
            icon: PercentageOutlined,
            breadcrumb: true,
            submenu: [],
          },
          {
            key: "cashback",
            path: `${APP_PREFIX_PATH}/apps/marketing/cashback`,
            title: "sidenav.apps.cashback",
            icon: DollarOutlined,
            breadcrumb: true,
            submenu: [],
          },
        ],
      },
      {
        key: "model",
        path: `${APP_PREFIX_PATH}/apps/model`,
        title: "sidenav.apps.model",
        icon: PicCenterOutlined,
        breadcrumb: true,
        submenu: [
          {
            key: "model-list",
            path: `${APP_PREFIX_PATH}/apps/model/model-list`,
            title: "sidenav.apps.model",
            icon: BookOutlined,
            breadcrumb: true,
            submenu: [],
          },
          {
            key: "devicemodel-list",
            path: `${APP_PREFIX_PATH}/apps/model/devicemodel-list`,
            title: "sidenav.apps.devicemodel",
            icon: BookOutlined,
            breadcrumb: true,
            submenu: [],
          },
        ],
      },
      {
        key: "products",
        path: `${APP_PREFIX_PATH}/apps/products`,
        title: "sidenav.apps.products",
        icon: ShoppingCartOutlined,
        breadcrumb: true,
        submenu: [],
      },
      {
        key: "actions",
        path: `${APP_PREFIX_PATH}/apps/actions`,
        title: "Actions",
        icon: TransactionOutlined,
        breadcrumb: true,
        submenu: [],
      },
      {
        key: "Currencies",
        path: `${APP_PREFIX_PATH}/apps/currencies`,
        title: "sidenav.apps.currencies",
        icon: MoneyCollectOutlined,
        breadcrumb: true,
        submenu: [
          {
            key: "currency-rate",
            path: `${APP_PREFIX_PATH}/apps/currencies/currency-rate`,
            title: "sidenav.apps.currencies.currency_rate",
            icon: RiseOutlined,
            breadcrumb: true,
            submenu: [],
          },
        ],
      },
      {
        key: "Reports",
        path: `${APP_PREFIX_PATH}/apps/reports`,
        title: "sidenav.apps.reports",
        icon: ProjectOutlined,
        breadcrumb: true,
        submenu: [
          {
            key: "client-order-prices",
            path: `${APP_PREFIX_PATH}/apps/reports/client-order-prices`,
            title: "sidenav.apps.reports.client_order_prices",
            icon: OneToOneOutlined,
            breadcrumb: true,
            submenu: [],
          },
          {
            key: "material-report",
            path: `${APP_PREFIX_PATH}/apps/reports/material-report`,
            title: "sidenav.apps.reports.material-report",
            icon: ContainerOutlined,
            breadcrumb: true,
            submenu: [],
          },
          {
            key: "profit-report",
            path: `${APP_PREFIX_PATH}/apps/reports/profit-report`,
            title: "sidenav.apps.reports.profit-report",
            icon: LoginOutlined,
            breadcrumb: true,
            submenu: [],
          },
          {
            key: "product-history",
            path: `${APP_PREFIX_PATH}/apps/reports/product-history`,
            title: "sidenav.apps.reports.product-history",
            icon: HistoryOutlined,
            breadcrumb: true,
            submenu: [],
          },
        ],
      },
      {
        key: "orders",
        path: `${APP_PREFIX_PATH}/apps/orders`,
        title: "sidenav.apps.orders",
        icon: BookOutlined,
        breadcrumb: true,
        submenu: [
          {
            key: "orders-list",
            path: `${APP_PREFIX_PATH}/apps/orders/order-list`,
            title: "sidenav.apps.orders",
            icon: BookOutlined,
            breadcrumb: true,
            submenu: [],
          },
          {
            key: "special-orders",
            path: `${APP_PREFIX_PATH}/apps/orders/special-orders`,
            title: "sidenav.apps.special-orders",
            icon: SnippetsOutlined,
            breadcrumb: true,
            submenu: [],
          },
          {
            key: "return-orders",
            path: `${APP_PREFIX_PATH}/apps/orders/return-order`,
            title: "sidenav.apps.products-actions.return-orders",
            icon: RollbackOutlined,
            breadcrumb: true,
            submenu: [],
          },
        ],
      },
      {
        key: "users",
        path: `${APP_PREFIX_PATH}/apps/users`,
        title: "sidenav.apps.users",
        icon: UserOutlined,
        breadcrumb: true,
        submenu: [
          {
            key: "user",
            path: `${APP_PREFIX_PATH}/apps/users/user`,
            icon: UserOutlined,
            title: "sidenav.apps.users",
            breadcrumb: true,
            submenu: [],
          },
          {
            key: "master-user",
            path: `${APP_PREFIX_PATH}/apps/users/master-user`,
            icon: UserOutlined,
            title: "sidenav.apps.master-users",
            breadcrumb: true,
            submenu: [],
          },
          {
            key: "business-partners",
            path: `${APP_PREFIX_PATH}/apps/users/business-partners`,
            icon: UserOutlined,
            title: "A.B.P",
            breadcrumb: true,
            submenu: [],
          },
          {
            key: "courier",
            path: `${APP_PREFIX_PATH}/apps/users/courier`,
            title: "sidenav.apps.couriers",
            icon: UserOutlined,
            breadcrumb: true,
            submenu: [],
          },
        ],
      },
      {
        key: "permissions",
        path: `${APP_PREFIX_PATH}/apps/permissions`,
        title: "sidenav.apps.permissions",
        icon: FileTextOutlined,
        breadcrumb: true,
        submenu: [
          {
            key: "modules-list",
            path: `${APP_PREFIX_PATH}/apps/permissions/modules-list`,
            icon: FileTextOutlined,
            title: "sidenav.apps.modules-list",
            breadcrumb: false,
            submenu: [],
          },
          {
            key: "roles-list",
            path: `${APP_PREFIX_PATH}/apps/permissions/roles-list`,
            icon: FileTextOutlined,
            title: "sidenav.apps.roles-list",
            breadcrumb: false,
            submenu: [],
          },
          {
            key: "role-permissions",
            path: `${APP_PREFIX_PATH}/apps/permissions/role-permissions`,
            icon: FileTextOutlined,
            title: "sidenav.apps.role-permissions",
            breadcrumb: false,
            submenu: [],
          },
        ],
      },
      {
        key: "deliveryPrice",
        path: `${APP_PREFIX_PATH}/apps/delivery-price`,
        title: "sidenav.apps.deliveryPrices",
        icon: NodeIndexOutlined,
        breadcrumb: true,
        submenu: [],
      },
      {
        key: "faq",
        path: `${APP_PREFIX_PATH}/apps/faq`,
        title: "sidenav.apps.faq",
        icon: QuestionCircleOutlined,
        breadcrumb: true,
        submenu: [],
      },
    ],
  },
];

const navigationConfig = [...dashBoardNavTree, ...appsNavTree];

export default navigationConfig;
