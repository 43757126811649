import { message } from "antd";
import { QueryClient } from "react-query";

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
    },
    mutations: {
      onError: (e) => {
        const { error, detail, nonfield_errors, ...rest } = e;

        if (error) message.error(error);
        if (detail) message.error(detail);

        if (nonfield_errors)
          return nonfield_errors.map((item) => message.error(item));

        if (rest) {
          Object.values(rest).map((err) =>
            typeof err === "string"
              ? message.error(rest[err])
              : err.map((item) => message.error(item))
          );
        }
      },
    },
  },
});

export default queryClient;
