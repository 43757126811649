import axios from "axios";
import { AUTH_TOKEN } from "redux/constants/Auth";
import { API_BASE_URL } from "./AppConfig";
import { signOut } from "redux/actions/Auth";
import { message } from "antd";

const request = axios.create({ baseURL: API_BASE_URL });

request.interceptors.request.use((config) => {
  // const user = JSON.parse(getStorage("user"));
  const jwtToken = localStorage.getItem(AUTH_TOKEN);
  const lanUser = localStorage.getItem("lang");
  if (jwtToken) {
    // eslint-disable-next-line no-param-reassign
    config.headers = {
      ...config.headers,
      Authorization: `Token ${jwtToken}`,
      "Accept-Language": `${lanUser}`,
    };
  }
  return config;
});

request.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error.response.status === 401) {
      signOut();
      window.location.href = "/auth/login";
      localStorage.clear();
    }
    if (error.response.data?.detail) {
      message.error(error?.response?.data?.detail)
    }
    return Promise.reject(error.response.data);
  }
);

export default request;
