import fetch from "auth/FetchInterceptor";

const authService = {};

authService.login = function (data) {
  return fetch({
    url: "/api/v1/user/login_for_admin/",
    method: "post",
    data: data,
  });
};

export default authService;
